$ = jQuery;

$(document).ready(function(){
    const commonSettings = {
        autoplay: true,
        loop: true,
        infinite: true,
        nav: true,
        rtl: false,
        dots: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true
    };

    const initializeCarousel = (selector, itemsSettings) => {
        $(selector).owlCarousel({
            ...commonSettings,
            items: itemsSettings.default,
            responsive: {
                0: {
                    items: itemsSettings[0],
                    nav: false
                },
                600: {
                    items: itemsSettings[600],
                    nav: true
                },
                1000: {
                    items: itemsSettings[1000],
                    nav: true
                }
            }
        });
    };

    initializeCarousel('.owl-carousel-services', { default: 2, 0: 1, 600: 1, 1000: 2, loop1000: false });
    initializeCarousel('.owl-carousel-cases', { default: 2, 0: 1, 600: 1, 1000: 4, loop1000: true });


document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
            const offset = 150;
            const targetPosition = target.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    });
});

});

jQuery ( document ).ready ( function($) {
    var hash= window.location.hash;
    if ( hash == '' || hash == '#' || hash == undefined )
        return false;

    var target = $(hash);
    target = target.length ? target : $('[name=' + hash.slice(1) +']');
    if (target.length) {
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 150
        }, 'linear');
    }
} );